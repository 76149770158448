import React, { useEffect, useState } from "react";
import "./custom.scss";
import RenderLogic from "../CustomContents/RenderLogic";
import useMobileVhFix from "../../../hooks/height";

const Custom = ({
  cancelClick,
  activeOrder,
  setPInfoDrawer,
  setTipsDrawerActive,
  step,
  setStep,
  update,
  update3,
  files,
  setFiles,
  setActiveOrder,
}) => {
  useEffect(() => {
    setStep(activeOrder.custom_status);
  }, [activeOrder]);
  useMobileVhFix();

  console.log(step);
  return (
    <div className="custom">
      <div className="shadow" onClick={cancelClick}></div>
      <RenderLogic
        cancelClick={cancelClick}
        setStep={setStep}
        setTipsDrawerActive={setTipsDrawerActive}
        step={step}
        activeOrder={activeOrder}
        setPInfoDrawer={setPInfoDrawer}
        update={update}
        update3={update3}
        files={files}
        setFiles={setFiles}
        setActiveOrder={setActiveOrder}
      />
    </div>
  );
};

export default Custom;
