import React, { useEffect, useState } from "react";
import "./navbar.scss";
import BtnStandardIconText from "../BtnStandardIconText/BtnStandardIconText";
import BtnCircular from "../BtnCircular/BtnCircular";
import { useNavigate } from "react-router-dom";
import NavBurger from "../NavBurger/NavBurger";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";
import LanguageBtn from "../../components/atoms/LanguageBtn/LanguageBtn";
import useOrderPrices from "../../hooks/prices";
import { useUnpaidOrders } from "../../hooks/orders";

import { ReactComponent as HandIcon } from "../../icons/hand.svg";

const Navbar = ({ setPDrawerActive }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [orderData, setOrderData] = useState(0);
  const [navHidden, setNavHidden] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const handleRoomClick = () => {
    navigate("/shipping-address");
  };

  const handleSettleClick = () => {
    setPDrawerActive(true);
  };

  // const { data: userData } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const { unpadidOrders } = useUnpaidOrders(userData.id);

  useEffect(() => {
    unpadidOrders?.filteredOrders?.length > 0
      ? setActive(true)
      : setActive(false);
    setSelectedOrders(unpadidOrders?.filteredOrders?.map((item) => item.id));
    setOrderData(unpadidOrders?.total);
  }, [unpadidOrders]);

  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (currentUrl.includes("landing") || currentUrl.includes("onboarding")) {
      setNavHidden(true);
    } else {
      setNavHidden(false);
    }
  }, [window.location.pathname]);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const { data } = useOrderPrices({
    orders: selectedOrders,
    address_id: userData?.customerAddresses[0]?.id,
  });

  return (
    <div className={navHidden ? "navbar-inner hidden" : "navbar-inner"}>
      <div className="gradient"></div>
      <div className="left">
        <BtnCircular
          size={"standard"}
          mainColor={"grayBorder"}
          svg={<HandIcon />}
          disabled={false}
        />
        <div className="txts">
          <div className="main">
            {Returntext(contentManagementData, "navbar_hello", lang)}{" "}
            {userData.first_name}
          </div>
          <div className="sub" onClick={handleRoomClick}>
            {Returntext(contentManagementData, "navbar_room", lang)}{" "}
            {userData.room_number.toUpperCase()}
          </div>
        </div>
      </div>
      <div className="right">
        {
          active && data !== undefined && data.length > 0 && (
            <BtnStandardIconText
              mode=""
              mainColor={"red"}
              txt={`${Returntext(
                contentManagementData,
                "navbar_btn_settle",
                lang
              )} ₾${
                data !== undefined && data.length > 0
                  ? data[1][0]?.weightCostBefore?.toFixed(2)
                  : "0.00"
              }`}
              disabled={false}
              size={"standard"}
              icon={false}
              iconLeft={false}
              onClick={handleSettleClick}
            />
          )
          // : (
          //   <BtnStandardIconText
          //     mode=""
          //     txt={Returntext(contentManagementData, "navbar_btn_settle", lang)}
          //     disabled={true}
          //     size={"standard"}
          //     icon={true}
          //     svg={<WalletIcon />}
          //     iconPosition={"left"}
          //     mainColor={"grayBorder"}
          //     onClick={handleSettleClick}
          //   />
          // )
        }
        <LanguageBtn />

        {/* <BtnCircular
          size={"standard"}
          mainColor={"grayBorder"}
          svg={<CommentIcon />}
          disabled={false}
        /> */}

        <NavBurger />
      </div>
    </div>
  );
};

export default Navbar;
