import React, { useEffect, useState } from "react";
import "./walletBox.scss";
import AccountDetailsBoxTitle from "../../atoms/AccountDetailsBoxTitle/AccountDetailsBoxTitle";
import ListItem from "../../../ds/ListItem/ListItem";
import BtnStandardIconText from "../../../ds/BtnStandardIconText/BtnStandardIconText";
import PaymentMethodsBox from "../PaymentMethodsBox/PaymentMethodsBox";
import { useContent, useLanguages } from "../../../hooks/content";
import { Returntext } from "../../../pages/Landings/common/returnText";
import useOrderPrices from "../../../hooks/prices";
import { useUnpaidOrders } from "../../../hooks/orders";
import { ReactComponent as WalletGIcon } from "../../../icons/wallet-g.svg";
import { ReactComponent as PayIcon } from "../../../icons/pay.svg";
import { ReactComponent as WalletIcon } from "../../../icons/wallet.svg";

const WalletBox = ({
  setActiveItem,
  activeItem,
  deleteItem,
  setDeleteItem,
  setShowModal,
  saveCard,
  setPDrawerActive,
}) => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const [active, setActive] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user"));

  const [orderData, setOrderData] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const { unpadidOrders } = useUnpaidOrders(userData.id);

  const handleSettleClick = () => {
    setPDrawerActive(true);
  };

  useEffect(() => {
    if (unpadidOrders?.filteredOrders?.length > 0) {
      setActive(true);
      setSelectedOrders(unpadidOrders?.filteredOrders?.map((item) => item.id));
      setOrderData(unpadidOrders?.total);
    }
  }, [unpadidOrders]);

  const { data } = useOrderPrices({
    orders: selectedOrders,
    address_id: userData?.customerAddresses[0]?.id,
  });

  return (
    <div className="wallet-box">
      <AccountDetailsBoxTitle
        main={Returntext(contentManagementData, "wallet_title", lang)}
        sub={Returntext(contentManagementData, "wallet_sub_title", lang)}
      />
      <ListItem
        hasBorder={false}
        hasPadding={true}
        hasIcon={true}
        icon={<WalletGIcon />}
        headline={""}
        description={Returntext(contentManagementData, "current_balance", lang)}
        gray={true}
        buttons={
          <>
            {active && data !== undefined && data.length > 0 ? (
              <BtnStandardIconText
                mode=""
                mainColor={"red"}
                txt={`${Returntext(
                  contentManagementData,
                  "navbar_btn_settle",
                  lang
                )} ₾${
                  data !== undefined && data.length > 0
                    ? data[1][0]?.weightCostBefore?.toFixed(2)
                    : "0.00"
                }`}
                disabled={false}
                size={"standard"}
                icon={false}
                iconLeft={false}
                onClick={handleSettleClick}
              />
            ) : (
              <BtnStandardIconText
                mode=""
                txt={"0.00₾"}
                disabled={true}
                size={"standard"}
                icon={true}
                svg={<WalletIcon />}
                iconPosition={"left"}
                mainColor={"grayBorder"}
                onClick={handleSettleClick}
              />
            )}
          </>
        }
      />

      <PaymentMethodsBox
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        setShowModal={setShowModal}
        saveCard={saveCard}
      />
    </div>
  );
};

export default WalletBox;
