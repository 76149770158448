import React from "react";
import BtnCircular from "../../../ds/BtnCircular/BtnCircular";
import TipsBody from "../../molecules/TipsBody/TipsBody";
import TipsFooter from "../../molecules/TipsFooter/TipsFooter";
import { useUpdateOrder } from "../../../hooks/orders";
import useUpdateOrderByNusxa from "../../../hooks/custom";
import { Returntext } from "../../../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../../../hooks/content";
import { ReactComponent as CloseIcon } from "../../../icons/x.svg";

const CustomContentS0 = ({ cancelClick, activeOrder, setStep }) => {
  const { updateOrderByNusxa } = useUpdateOrderByNusxa();
  const { refetchOrdersOnSuccess } = useUpdateOrder();

  const handleUpdateManual = async () => {
    try {
      const res = await updateOrderByNusxa({
        id: activeOrder?.id,
        newData: { custom_status: 1 },
      });
      console.log(res);
      await refetchOrdersOnSuccess();
      setStep("1");
    } catch (error) {
      console.log(error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  return (
    <div className="drawer-wrap">
      <div className="header">
        <div className="left"></div>

        <div className="middle">
          {Returntext(contentManagementData, "customDrawerT", lang)}
        </div>

        <BtnCircular
          size={"sm"}
          mainColor={"white"}
          svg={<CloseIcon />} // Use the SVG component here
          disabled={false}
          mode={"active"}
          onClick={cancelClick}
        />
      </div>

      <div className="body">
        <TipsBody />
      </div>

      <div className="footer">
        <TipsFooter onClick={() => handleUpdateManual()} />
      </div>
    </div>
  );
};

export default CustomContentS0;
