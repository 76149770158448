import React, { useState } from "react";
import AccountSettingsTitle from "../../components/atoms/AccountSettingsTitle/AccountSettingsTitle";
import "./services.scss";
import ServiceBox from "../../components/molecules/ServiceBox/ServiceBox";
import { useUserData } from "../../hooks/users";
import axios from "axios";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../Landings/common/returnText";
import Cookies from "js-cookie";
import { ReactComponent as RepacIcon } from "../../icons/repac.svg";
import { ReactComponent as PiIcon } from "../../icons/pi.svg";

const Services = () => {
  const { refetch } = useUserData();
  const userData = JSON.parse(localStorage.getItem("user"));

  const [repac, setRepac] = useState(userData?.repackaging);
  const [pcio, setPcio] = useState(userData?.payCustomsInsteadOfMe);

  const [pi, setPi] = useState(true);
  const access_token = Cookies.get("access_token");

  const updateCustomerData = async (value) => {
    try {
      console.log(repac);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/updateCustomer/${userData.id}?accessToken=${access_token}`,
        {
          repackaging: value,
        }
      );
      refetch();
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  const updateCustomerDataPCIO = async (value) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/customers/updateCustomer/${userData.id}?accessToken=${access_token}`,
        {
          payCustomsInsteadOfMe: value,
        }
      );
      console.log("Customer updated successfully!");
      refetch();
    } catch (error) {
      console.error("Error updating customer:", error);
    }
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  console.log(pcio);

  return (
    <div className="services">
      <div className="main-content">
        <AccountSettingsTitle
          main={Returntext(contentManagementData, "servicesHeadline", lang)}
          sub={Returntext(contentManagementData, "servicesSubheadline", lang)}
        />

        <div className="service-box-wrap">
          <ServiceBox
            main={Returntext(
              contentManagementData,
              "servicesBOX1Subheadline",
              lang
            )}
            sub={Returntext(contentManagementData, "servicesBOX1Body", lang)}
            icon={RepacIcon}
            value={repac}
            setter={setRepac}
            updateCustomerData={updateCustomerData}
          />

          <ServiceBox
            main={Returntext(
              contentManagementData,
              "servicesBOX2Headline",
              lang
            )}
            sub={Returntext(contentManagementData, "servicesBOX2Body", lang)}
            icon={PiIcon}
            value={pi}
            setter={setPi}
            disabled={true}
            updateCustomerData={updateCustomerData}
            style={{ backgroundColor: "#d1cec7" }}
          />

          <ServiceBox
            main={Returntext(
              contentManagementData,
              "servicesBOX3Headline",
              lang
            )}
            sub={Returntext(contentManagementData, "servicesBOX3Body", lang)}
            icon={PiIcon}
            value={pcio}
            setter={setPcio}
            disabled={false}
            updateCustomerData={updateCustomerDataPCIO}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
