import React from "react";
import "./sidebar.scss";
import SidebarBody from "../SidebarBody/SidebarBody";
import SidebarFooter from "../SidebarFooter/SidebarFooter";
import { useContent, useLanguages } from "../../hooks/content";
import { Returntext } from "../../pages/Landings/common/returnText";

// Import SVGs as React components
import { ReactComponent as GzLogoIcon } from "../../icons/gz-icon-lg.svg";
import { ReactComponent as TabIcon } from "../../icons/tab-icon.svg";
import { ReactComponent as OrdersIcon } from "../../icons/nav-icons/normal/orders.svg";
import { ReactComponent as OrdersIconDisabled } from "../../icons/nav-icons/normal/orders.svg";
import { ReactComponent as OrdersIconActive } from "../../icons/nav-icons/active/orders.svg";
import { ReactComponent as WalletIcon } from "../../icons/nav-icons/normal/wallet.svg";
import { ReactComponent as WalletIconDisabled } from "../../icons/nav-icons/normal/wallet.svg";
import { ReactComponent as WalletIconActive } from "../../icons/nav-icons/active/wallet.svg";
import { ReactComponent as PinIcon } from "../../icons/nav-icons/normal/pin.svg";
import { ReactComponent as PinIconDisabled } from "../../icons/nav-icons/normal/pin.svg";
import { ReactComponent as PinIconActive } from "../../icons/nav-icons/active/pin.svg";
import { ReactComponent as DeliveryIcon } from "../../icons/nav-icons/normal/delivery.svg";
import { ReactComponent as DeliveryIconDisabled } from "../../icons/nav-icons/normal/delivery.svg";
import { ReactComponent as DeliveryIconActive } from "../../icons/nav-icons/active/delivery.svg";
import { ReactComponent as ServiceIcon } from "../../icons/nav-icons/normal/service.svg";
import { ReactComponent as ServiceIconDisabled } from "../../icons/nav-icons/normal/service.svg";
import { ReactComponent as ServiceIconActive } from "../../icons/nav-icons/active/service.svg";
import { ReactComponent as SettingsIcon } from "../../icons/nav-icons/normal/settings.svg";
import { ReactComponent as SettingsIconDisabled } from "../../icons/nav-icons/normal/settings.svg";
import { ReactComponent as SettingsIconActive } from "../../icons/nav-icons/active/settings.svg";

const Sidebar = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const version = process.env.REACT_APP_VERSION || "1.4.0";

  const footerLinks = [
    {
      name: `V.${version}`,
    },
  ];

  const headIcons = {
    gzLogo: <GzLogoIcon />,
    tabIcon: <TabIcon />,
  };

  const navButtons = [
    {
      icon: <OrdersIcon className="icon" />,
      txt: Returntext(contentManagementData, "orders", lang),
      paths: [
        "/orders/AWAITING",
        "/orders/RECEIVED",
        "/orders/ON_THE_WAY_FROM_US_WAREHOUSE",
        "/orders/RECEIVED_IN_TBILISI_WAREHOUSE",
        "/orders/DELIVERED",
      ],
    },
    {
      icon: <WalletIcon className="icon" />,
      txt: Returntext(contentManagementData, "wallet", lang),
      paths: ["/wallet"],
    },
    {
      icon: <PinIcon className="icon" />,
      txt: Returntext(contentManagementData, "shipping_address", lang),
      paths: ["/shipping-address"],
    },
    {
      icon: <DeliveryIcon className="icon" />,
      txt: Returntext(contentManagementData, "delivery", lang),
      paths: ["/delivery"],
    },
    {
      icon: <ServiceIcon className="icon" />,
      txt: Returntext(contentManagementData, "services", lang),
      paths: ["/services"],
    },
    {
      icon: <SettingsIcon className="icon" />,
      txt: Returntext(contentManagementData, "settings", lang),
      paths: ["/account-settings"],
    },
  ];

  return (
    <div className="sidebar">
      <SidebarBody headIcons={headIcons} navButtons={navButtons} />
      <SidebarFooter footerLinks={footerLinks} />
    </div>
  );
};

export default Sidebar;
