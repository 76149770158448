import React from "react";
import "./sidebarFooter.scss";
import { Link } from "react-router-dom";

const SidebarFooter = ({ footerLinks }) => {
  return (
    <div className="sidebar-footer">
      <div className="txt-wrap">
        {footerLinks.map((item) => (
          <Link to={item.link} className="txt" key={item.link}>
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SidebarFooter;
